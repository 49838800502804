<template lang="pug">
  div#ivaRetenciones
    v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    v-dialog(fullscreen="" v-model='dialodRetecion')
      v-card
        retencion-modal(@closeModal="closeModal" :retencionToEdit="retencion")
    base-material-card.px-5.py-3(icon='mdi-newspaper' title='IVA / RETENCIONES (FACTURAS DE COMPRA)' style='min-height: 100vh;')
      v-alert(v-model="alertDataSend" dismissible dense='' border='left' type='info')
        | Puede ingresar los códigos de retenciones manualmente que su proveedor realice en sus compras.
      v-container#iva-retenciones-table(fluid='' tag='section')
        v-card-title.mb-5
          v-row
            v-col(cols="12" md="1")
              h3 IVA :
            v-col(cols="10" md="10")
              v-text-field.pl-1.pt-7(dense outlined :rules="[rules.required]" v-model="iva" type="text" @keypress="isNumber($event)" @keydown="validar($event, iva, 2)" autocomplete="off" suffix="%" class="inputNumber centered-input text--darken-3" style="padding-top: 0px !important;")
                template(#label="")
                  span.red--text
                    strong *
                  | {{ ' ' + 'Porcentaje del IVA (%)' }}
            v-col(cols="2" md="1")
              v-tooltip(bottom="")
                template(v-slot:activator="{ on: tooltip }")
                  v-btn(icon="" color="primary" v-on="{...tooltip, ...menu}" @click="guardarIva" :loading="loadingSaveIva")
                    v-icon(style="font-size: 30px;") mdi-content-save
                span.tooltips Guardar IVA
        v-row
          v-col(cols="12" md="6")
            v-divider.mx-4(inset="" vertical="")
            //- *************************** Buscador ***************************
            v-text-field(v-model="search" append-icon="mdi-search" label= "Buscar" single-line="" hide-details="" clearable)
          v-col(cols="12" md="3")
            v-btn.mr-0(color='primary' rounded='' @click='addNew' style="width: 100%;")
              strong
                | NUEVA
                br
                | RETENCIÓN
          v-col(cols="12" md="3")
            v-btn.mr-0(color='secondary' rounded='' @click='downLoadExcelFile' style="width: 100%;")
              strong
                | DESCARGAR
                br
                | LISTA
      v-skeleton-loader(:loading="cargando" type="table" v-if="firstLoad")
      v-data-table.mx-2#tablaDatos(:headers="headers" v-show="!firstLoad" :items="ivaRetencionesLst" item-key="uid" :search="search" dense fixed-header :loading="cargando" loading-text="Cargando" :sort-by="ordenarPor" calculate-widths :footer-props="{\'items-per-page-text':'Retenciones por página'\}")
        //- Da color a un dato de las filas de la tabla
        template(v-slot:item.type.text="{ item }")
          v-chip(:color="getColor(item.active)" small label) {{ item.type.text }}
        //- Acciones de los botones que tiene la tabla
        template(v-slot:item.action="{ item }")
          v-tooltip(bottom="")
            template(v-slot:activator="{ on }")
              v-btn(text="" icon="" color='blue' v-on="on" @click="editItem(item)")
                v-icon(small) mdi-lead-pencil
            span.tooltips Editar
        v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
          | Sin resultados
        v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
          | Sin datos
        v-flex(xs12='', sm10='', offset-sm1='')
</template>
<script>
  import * as functions from '@/functions'

  export default {
    components: {
      RetencionModal: () => import('@/views/dashboard/component/application/RetencioneModal'),
    },
    data: () => ({
      alertDataSend: true,
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      menu: false,
      search: '',
      dialodRetecion: false,
      cargando: false,
      firstLoad: false,
      loadingSaveIva: false,
      iva: '',
      rules: {
        required: value => !!value || 'Requerido',
      },
      ordenarPor: 'codigo',
      headers: [
        { text: 'Código', value: 'codigo', align: 'left', filterable: true },
        { text: 'Nombre', value: 'nombre', align: 'left', filterable: true },
        { text: 'Porcentaje', value: 'porcentaje', align: 'left', filterable: true },
        { text: 'Tipo', value: 'tipo.nombre', align: 'left', filterable: true },
        { text: 'Editar', align: 'right', value: 'action', sortable: false }, // No se cambia
      ],
      retencion: {
        codigo: '',
        nombre: '',
        porcentaje: '',
        tipo: null,
        completedata: '',
        filterdata: '',
        retencionKey: '',
      },
    }),
    computed: {
      ivaVal () {
        return this.$store.state.ivaretenciones.iva
      },
      ivaRetencionesLst () {
        return this.$store.state.ivaretenciones.retencionesLst
      },
    },
    watch: {
      ivaVal () {
        this.iva = this.ivaVal
      },
    },
    created () {
      this.iva = this.$store.state.ivaretenciones.iva
    },
    mounted () {
      this.alertDataSend = true
    },
    methods: {
      isNumber (evt) {
        evt = (evt) || window.event
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault()
        } else {
          return true
        }
      },
      validar ($event, value, longitud) {
        const key = $event.keyCode ? $event.keyCode : $event.which
        if (key !== 8 && key !== 37 && key !== 39 && key !== 46 && key !== 35 && key !== 36 && key !== 9) {
          if (!/\d/.test($event.key) || value.length >= longitud) {
            return $event.preventDefault()
          }
        }
      },
      guardarIva () {
        this.loadingSaveIva = true
        const ivaInt = parseInt(this.iva)
        if (ivaInt <= 0) {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'El IVA no puede ser menor o igual a CERO.',
          }
          this.loadingSaveIva = false
        } else {
          this.loadingSaveIva = true
          this.$store.dispatch('ivaretenciones/guardarValorIva', this.iva).then(res => {
            this.loadingSaveIva = false
            this.snackbar = {
              show: true,
              color: 'green',
              text: 'Porcentaje guardado de manera correcta.',
            }
          }, reject => {
            this.loadingSaveIva = false
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al momento de guardar el porcentaje.',
            }
          })
        }
      },
      addNew () {
        this.dialodRetecion = true
        this.retencion = {
          codigo: '',
          nombre: '',
          porcentaje: '',
          tipo: null,
          completedata: '',
          filterdata: '',
          retencionKey: '',
        }
      },
      downLoadExcelFile () {
        const data = []
        if (this.ivaRetencionesLst) {
          for (let i = 0; i < this.ivaRetencionesLst.length; i++) {
            data.push({
              Código: this.ivaRetencionesLst[i].codigo,
              Nombre: this.ivaRetencionesLst[i].nombre,
              Porcentaje: this.ivaRetencionesLst[i].porcentaje.replace('.', ','),
              Tipo: this.ivaRetencionesLst[i].tipo.nombre,
            })
          }
          functions.JSONToCSVConvertor(data, 'Retenciones', true)
        }
      },
      closeModal () {
        this.dialodRetecion = false
      },
      editItem (item) {
        this.dialodRetecion = true
        this.retencion = item
      },
    },
  }
</script>
